import React from "react"
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css"
import { Avatar, Conversation } from "@chatscope/chat-ui-kit-react"
import { getUser } from "../../../../services/auth"


import { useCollectionData } from "react-firebase-hooks/firestore"

import firebase from "firebase/app"
import "firebase/firestore"


const firestore = firebase.firestore()

const GroupChatList = ({
  groupChatList,
  usersFromServer,
  handleDialogChange,
}) => {
  let defaultChatGroup = {}

  const theUser = getUser()

  const groupchatsRef = firestore.collection("groupchat")
  const query = groupchatsRef.orderBy("createdAt").limit(1000)

  const [groupchats] = useCollectionData(query, { idField: "id" })

  return (
    <>
      {groupchats
        ?.filter((gcs) => gcs.occupants_ids.includes(theUser.userId))
        .map((value) => (
          <Conversation
            lastSenderName={value.lastSenderName}
            name={value.name}
            info={value.last_message ? value.last_message : "No messages yet"}
            onClick={() => {
              handleDialogChange(value)
            }}
            lastActivityTime={`${value.occupants_ids.length} members`}
            unreadCnt={value.unread_messages_count}
          >
            <Avatar
              src={`https://via.placeholder.com/150/3d9afc/fdc300?text=${value.name
                .split(" ")
                .map((x) => x[0])
                .join("")
                .toUpperCase()}`}
              name={value.name}
            />
          </Conversation>
        ))}
    </>
  )
}

export default GroupChatList
